html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
}

div#root {
  height: 100%;
  width: 100%;
}